export const colorTileData = [
	{
		name: 'White',
		hex: '#FFFFFF',
		rgb: 'rgb(255, 255, 255)',
		hsl: 'hsl(0, 0%, 100%)',
		token: '@color-white',
		type: 'light',
	},
	{
		name: 'Black',
		hex: '#000000',
		rgb: 'rgb(0, 0, 0)',
		hsl: 'hsl(0, 0%, 0%)',
		token: '@color-black',
		type: 'dark',
	},
	{
		name: '--',
		hex: '#EEEEEE',
		rgb: 'rgb(238, 238, 238)',
		hsl: 'hsl(0, 0%, 93.3%)',
		token: '@color-base---',
		type: 'light',
	},
	{
		name: 'Light Ash',
		hex: '#E7E9E9',
		rgb: 'rgb(231, 233, 233)',
		hsl: 'hsl(180, 4.3%, 91%)',
		token: '@color-light-ash',
		type: 'light',
	},
	{
		name: 'Ash',
		hex: '#D0D3D4',
		rgb: 'rgb(208, 211, 212)',
		hsl: 'hsl(195, 4.4%, 82.4%)',
		token: '@color-ash',
		type: 'light',
	},
	{
		name: 'Slate',
		hex: '#425563',
		rgb: 'rgb(66, 85, 99)',
		hsl: 'hsl(205.5, 20%, 32.4%)',
		token: '@color-slate',
		type: 'dark',
	},
	{
		name: 'Steel',
		hex: '#7C878E',
		rgb: 'rgb(124, 135, 142)',
		hsl: 'hsl(203, 7.4%, 52.2%)',
		token: '@color-steel',
		type: 'dark',
	},
	{
		name: 'Chart 1',
		hex: '#6699FF',
		rgb: 'rgb(102, 153, 255)',
		hsl: 'hsl(220, 100%, 70%)',
		token: '@color-chart-01',
		type: 'dark',
	},
	{
		name: 'Chart 2',
		hex: '#FC814C',
		rgb: 'rgb(252, 129, 76)',
		hsl: 'hsl(18, 96.7%, 64.3%)',
		token: '@color-chart-02',
		type: 'light',
	},
	{
		name: 'Chart 3',
		hex: '#99F99F',
		rgb: 'rgb(153, 249, 159)',
		hsl: 'hsl(123.8, 88.9%, 78.8%)',
		token: '@color-chart-03',
		type: 'light',
	},
	{
		name: 'Chart 4',
		hex: '#CC99FF',
		rgb: 'rgb(204, 153, 255)',
		hsl: 'hsl(270, 100%, 80%)',
		token: '@color-chart-04',
		type: 'light',
	},
	{
		name: 'Chart 5',
		hex: '#FFD35B',
		rgb: 'rgb(255, 211, 91)',
		hsl: 'hsl(40, 100%, 67.8%)',
		token: '@color-chart-05',
		type: 'light',
	},
	{
		name: 'Chart 6',
		hex: '#FF551E',
		rgb: 'rgb(255, 85, 30)',
		hsl: 'hsl(15, 100%, 55.9%)',
		token: '@color-chart-06',
		type: 'dark',
	},
	{
		name: 'Chart 7',
		hex: '#99CCCC',
		rgb: 'rgb(153, 204, 204)',
		hsl: 'hsl(180, 33.3%, 70%)',
		token: '@color-chart-07',
		type: 'light',
	},

	{
		name: 'Informational',
		hex: '#1966B3',
		rgb: 'rgb(25, 102, 179)',
		hsl: 'hsl(210, 75.5%, 40.0%)',
		token: '@color-base-status-informational',
		type: 'dark',
	},
	{
		name: 'Orange 50',
		hex: '#FFE6D9',
		rgb: 'rgb(255, 230, 217)',
		hsl: 'hsl(21, 100%, 92.5%)',
		token: '@color-orange-50',
		type: 'light',
	},
	{
		name: 'Orange 100',
		hex: '#FFD4BF',
		rgb: 'rgb(255, 212, 191)',
		hsl: 'hsl(20, 100%, 87.5%)',
		token: '@color-orange-100',
		type: 'light',
	},
	{
		name: 'Orange 200',
		hex: '#FFBB99',
		rgb: 'rgb(255, 187, 153)',
		hsl: 'hsl(20, 100%, 80%)',
		token: '@color-orange-200',
		type: 'light',
	},
	{
		name: 'Orange 250',
		hex: '#F3C781',
		rgb: 'rgb(243, 199, 129)',
		hsl: 'hsl(37, 82.6%, 72.9%)',
		token: '@color-orange-250',
		type: 'light',
	},
	{
		name: 'Orange 275',
		hex: '#F6C664',
		rgb: 'rgb(246, 198, 100)',
		hsl: 'hsl(40.3, 89%, 67.8%)',
		token: '@color-orange-275',
		type: 'light',
	},
	{
		name: 'Orange 300',
		hex: '#FF9966',
		rgb: 'rgb(255, 153, 102)',
		hsl: 'hsl(20, 100%, 70%)',
		token: '@color-orange-300',
		type: 'light',
	},
	{
		name: 'Orange 350',
		hex: '#F39681',
		rgb: 'rgb(243, 150, 129)',
		hsl: 'hsl(11, 82.6%, 72.9%)',
		token: '@color-orange-350',
		type: 'light',
	},
	{
		name: 'Orange 375',
		hex: '#FF9C00',
		rgb: 'rgb(255, 156, 0)',
		hsl: 'hsl(36.7, 100%, 50%)',
		token: '@color-orange-375',
		type: 'dark',
	},
	{
		name: 'Orange 400',
		hex: '#FF8040',
		rgb: 'rgb(255, 128, 64)',
		hsl: 'hsl(20.1, 100%, 62.5%)',
		token: '@color-orange-400',
		type: 'light',
	},
	{
		name: 'Orange 450',
		hex: '#C67839',
		rgb: 'rgb(198, 120, 57)',
		hsl: 'hsl(27, 55.3%, 50%)',
		token: '@color-orange-450',
		type: 'dark',
	},
	{
		name: 'Orange 500',
		hex: '#FF5500',
		rgb: 'rgb(255, 85, 0)',
		hsl: 'hsl(20, 100%, 50%)',
		token: '@color-orange-500',
		type: 'dark',
	},
	{
		name: 'Orange 700',
		hex: '#C14100',
		rgb: 'rgb(193, 65, 0)',
		hsl: 'hsl(20.2, 100%, 37.8%)',
		token: '@color-orange-700',
		type: 'dark',
	},
	{
		name: 'Orange 800',
		hex: '#A23700',
		rgb: 'rgb(162, 55, 0)',
		hsl: 'hsl(20.4, 100%, 31.8%)',
		token: '@color-orange-800',
		type: 'dark',
	},
	{
		name: 'Orange 900',
		hex: '#923100',
		rgb: 'rgb(146, 49, 0)',
		hsl: 'hsl(20.1, 100%, 28.6%)',
		token: '@color-orange-900',
		type: 'dark',
	},
	{
		name: 'Navy 25',
		hex: '#F8F9F9',
		rgb: 'rgb(248, 249, 249)',
		hsl: 'hsl(180, 7.7%, 97.5%)',
		token: '@color-navy-25',
		type: 'light',
	},
	{
		name: 'Navy 50',
		hex: '#E9EBEC',
		rgb: 'rgb(233, 235, 236)',
		hsl: 'hsl(200, 7.3%, 92.0%)',
		token: '@color-navy-50',
		type: 'light',
	},
	{
		name: 'Navy 100',
		hex: '#C8CDD1',
		rgb: 'rgb(200, 205, 209)',
		hsl: 'hsl(207, 8.9%, 80.2%)',
		token: '@color-navy-100',
		type: 'light',
	},
	{
		name: 'Navy 200',
		hex: '#A8AFB5',
		rgb: 'rgb(168, 175, 181)',
		hsl: 'hsl(207.7, 8.1%, 68.4%)',
		token: '@color-navy-200',
		type: 'light',
	},
	{
		name: 'Navy 300',
		hex: '#7C8790',
		rgb: 'rgb(124, 135, 144)',
		hsl: 'hsl(213, 8.3%, 52.5%)',
		token: '@color-navy-300',
		type: 'light',
	},
	{
		name: 'Navy 400',
		hex: '#515F6B',
		rgb: 'rgb(81, 95, 107)',
		hsl: 'hsl(208, 13.8%, 36.9%)',
		token: '@color-navy-400',
		type: 'dark',
	},
	{
		name: 'Navy 500',
		hex: '#253746',
		rgb: 'rgb(37, 55, 70)',
		hsl: 'hsl(207.3, 30.8%, 21%)',
		token: '@color-navy-500',
		type: 'dark',
	},
	{
		name: 'Navy 600',
		hex: '#15293A',
		rgb: 'rgb(21, 41, 58)',
		hsl: 'hsl(208, 46.8%, 15.5%)',
		token: '@color-navy-600',
		type: 'dark',
	},
	{
		name: 'Navy 700',
		hex: '#081826',
		rgb: 'rgb(8, 24, 38)',
		hsl: 'hsl(208, 65.2%, 9%)',
		token: '@color-navy-700',
		type: 'dark',
	},
	{
		name: 'Cyan 50',
		hex: '#E5F6fA ',
		rgb: 'rgb(229, 246, 250)',
		hsl: 'hsl(191.4, 67.7%, 93.9%)',
		token: '@color-cyan-50',
		type: 'light',
	},
	{
		name: 'Cyan 100',
		hex: '#D3F0F7',
		rgb: 'rgb(149, 219, 236)',
		hsl: 'hsl(192, 69.6%, 75.5%)',
		token: '@color-cyan-300',
		type: 'light',
	},
	{
		name: 'Cyan 200',
		hex: '#B8E7F3',
		rgb: 'rgb(184, 231, 243)',
		hsl: 'hsl(192.2, 71.1%, 83.7%)',
		token: '@color-cyan-200',
		type: 'light',
	},
	{
		name: 'Cyan 300',
		hex: '#95DBEC',
		rgb: 'rgb(149, 219, 236)',
		hsl: 'hsl(192, 69.2%, 89.8%)',
		token: '@color-cyan-100',
		type: 'light',
	},
	{
		name: 'Cyan 400',
		hex: '#71CFE6',
		rgb: 'rgb(113, 207, 230)',
		hsl: 'hsl(192, 70.1%, 67.3%)',
		token: '@color-cyan-400',
		type: 'light',
	},
	{
		name: 'Cyan 500',
		hex: '#4EC3E0',
		rgb: 'rgb(78, 195, 224)',
		hsl: 'hsl(191.9, 70.2%, 59.2%)',
		token: '@color-cyan-500',
		type: 'light',
	},
	{
		name: 'Cyan 600',
		hex: '#2CB2D2',
		rgb: 'rgb(44, 178, 210)',
		hsl: 'hsl(191.6, 65.4%, 49.8%)',
		token: '@color-cyan-600',
		type: 'light',
	},
	{
		name: 'Cyan 700',
		hex: '#089BBE',
		rgb: 'rgb(8, 155, 190)',
		hsl: 'hsl(191.5, 91.9%, 38.8%)',
		token: '@color-cyan-700',
		type: 'dark',
	},
	{
		name: 'Cyan 800',
		hex: '#007D9A',
		rgb: 'rgb(0, 125, 154)',
		hsl: 'hsl(191.3, 100%, 30.2%)',
		token: '@color-cyan-800',
		type: 'dark',
	},
	{
		name: 'Cyan 900',
		hex: '#066C84',
		rgb: 'rgb(6, 108, 132)',
		hsl: 'hsl(191.4, 91.3%, 27.1%)',
		token: '@color-cyan-900',
		type: 'dark',
	},
	{
		name: 'Cyan 950',
		hex: '#125667',
		rgb: 'rgb(18, 86, 103)',
		hsl: 'hsl(192, 70.2%, 23.7%)',
		token: '@color-cyan-950',
		type: 'dark',
	},
	{
		name: 'Black 50',
		hex: '#fcfcfc',
		rgb: 'rgb(252, 252, 252)',
		hsl: 'hsl(0, 0%, 99%)',
		token: '@color-black-50',
		type: 'light',
	},
	{
		name: 'Black 100',
		hex: '#f9f9f9',
		rgb: 'rgb(250, 250, 250)',
		hsl: 'hsl(0, 0%, 98%)',
		token: '@color-black-100',
		type: 'dark',
	},
	{
		name: 'Black 200',
		hex: '#dedede',
		rgb: 'rgb(222, 222, 222)',
		hsl: 'hsl(0, 0%, 87%)',
		token: '@color-black-200',
		type: 'light',
	},
	{
		name: 'Black 300',
		hex: '#bcbcbc',
		rgb: 'rgb(188, 188, 188)',
		hsl: 'hsl(0, 0%, 74%)',
		token: '@color-black-300',
		type: 'light',
	},
	{
		name: 'Black 400',
		hex: '#949494',
		rgb: 'rgb(148, 148, 148)',
		hsl: 'hsl(0, 0%, 58%)',
		token: '@color-black-400',
		type: 'light',
	},
	{
		name: 'Black 500',
		hex: '#6b6b6b',
		rgb: 'rgb(107, 107, 107)',
		hsl: 'hsl(0, 0%, 42%)',
		token: '@color-black-500',
		type: 'dark',
	},
	{
		name: 'Black 600',
		hex: '#595959',
		rgb: 'rgb(89, 89, 89)',
		hsl: 'hsl(0, 0%, 35%)',
		token: '@color-black-600',
		type: 'dark',
	},
	{
		name: 'Black 700',
		hex: '#474747',
		rgb: 'rgb(71, 71, 71)',
		hsl: 'hsl(0, 0%, 28%)',
		token: '@color-black-700',
		type: 'dark',
	},
	{
		name: 'Black 800',
		hex: '#3b3b3b',
		rgb: 'rgb(59, 59, 59)',
		hsl: 'hsl(0, 0%, 23%)',
		token: '@color-black-800',
		type: 'dark',
	},
	{
		name: 'Black 900',
		hex: '#242424',
		rgb: 'rgb(36, 36, 36)',
		hsl: 'hsl(0, 0%, 14%)',
		token: '@color-black-900',
		type: 'dark',
	},
	{
		name: 'Black 950',
		hex: '#121212',
		rgb: 'rgb(18, 18, 18)',
		hsl: 'hsl(0, 0%, 7%)',
		token: '@color-black-950',
		type: 'dark',
	},
	{
		name: 'Gray 25',
		hex: '#fcfcfc ',
		rgb: 'rgb(252, 252, 252)',
		hsl: 'hsl(0, 0%, 99%)',
		token: '@color-gray-25',
		type: 'light',
	},
	{
		name: 'Gray 50',
		hex: '#fafafa',
		rgb: 'rgb(250, 250, 250)',
		hsl: 'hsl(0, 0%, 98%)',
		token: '@color-gray-50',
		type: 'light',
	},
	{
		name: 'Gray 100',
		hex: '#f4f5f7',
		rgb: 'rgb(244, 245, 247)',
		hsl: 'hsl(220, 16%, 96%)',
		token: '@color-gray-100',
		type: 'light',
	},
	{
		name: 'Gray 200',
		hex: '#e4e7ec',
		rgb: 'rgb(228, 231, 236)',
		hsl: 'hsl(218, 17%, 91%)',
		token: '@color-gray-200',
		type: 'light',
	},
	{
		name: 'Gray 300',
		hex: '#d1d1d6',
		rgb: 'rgb(209, 209, 214)',
		hsl: 'hsl(240, 6%, 83%)',
		token: '@color-gray-300',
		type: 'light',
	},
	{
		name: 'Gray 400',
		hex: '#9da4ae',
		rgb: 'rgb(157, 164, 174)',
		hsl: 'hsl(215, 9%, 65%)',
		token: '@color-gray-400',
		type: 'light',
	},
	{
		name: 'Gray 500',
		hex: '#6c737f',
		rgb: 'rgb(108, 115, 127)',
		hsl: 'hsl(218, 8%, 46%)',
		token: '@color-gray-500',
		type: 'dark',
	},
	{
		name: 'Gray 600',
		hex: '#475467',
		rgb: 'rgb(71, 84, 102)',
		hsl: 'hsl(216, 18%, 34%)',
		token: '@color-gray-600',
		type: 'dark',
	},
	{
		name: 'Gray 700',
		hex: '#384250',
		rgb: 'rgb(56, 67, 81)',
		hsl: 'hsl(215, 18%, 27%)',
		token: '@color-gray-700',
		type: 'dark',
	},
	{
		name: 'Gray 800',
		hex: '#1f2a37',
		rgb: 'rgb(31, 42, 55)',
		hsl: 'hsl(213, 28%, 17%)',
		token: '@color-gray-800',
		type: 'dark',
	},
	{
		name: 'Gray 900',
		hex: '#111927',
		rgb: 'rgb(17, 25, 39)',
		hsl: 'hsl(218, 39%, 11%)',
		token: '@color-gray-900',
		type: 'dark',
	},
	{
		name: 'Gray 950',
		hex: '#0c111d',
		rgb: 'rgb(12, 17, 29)',
		hsl: 'hsl(222, 41%, 8%)',
		token: '@color-gray-950',
		type: 'dark',
	},
	{
		name: 'Red 50',
		hex: '#FF8593',
		rgb: 'rgb(255, 133, 147)',
		hsl: 'hsl(355, 100%, 76.1%)',
		token: '@color-red-200',
		type: 'light',
	},
	{
		name: 'Red 100',
		hex: '#C1001B',
		rgb: 'rgb(193, 0, 27)',
		hsl: 'hsl(346.6, 100%, 37.8%)',
		token: '@color-red-600',
		type: 'dark',
	},
	{
		name: 'Red 200',
		hex: '#783A3B',
		rgb: 'rgb(120, 58, 59)',
		hsl: 'hsl(359, 34.8%, 34.9%)',
		token: '@color-red-800',
		type: 'dark',
	},
	{
		name: 'Rose 100',
		hex: '#fe5666',
		rgb: 'rgb(254, 86, 102)',
		hsl: 'hsl(354, 99%, 67%)',
		token: '@color-rose-100',
		type: 'light',
	},
	{
		name: 'Rose 200',
		hex: '#8c2000',
		rgb: 'rgb(140, 32, 0)',
		hsl: 'hsl(14, 100%, 27%)',
		token: '@color-rose-200',
		type: 'dark',
	},
	{
		name: 'Sunset 100',
		hex: '#ff9c68',
		rgb: 'rgb(255, 156, 104)',
		hsl: 'hsl(21, 100%, 70%)',
		token: '@color-sunset-100',
		type: 'light',
	},
	{
		name: 'Sunset 200',
		hex: '#f58509',
		rgb: 'rgb(245, 133, 9)',
		hsl: 'hsl(32, 93%, 50%)',
		token: '@color-sunset-200',
		type: 'light',
	},
	{
		name: 'Sunset 300',
		hex: '#fcdf7d',
		rgb: 'rgb(252, 222, 126)',
		hsl: 'hsl(46, 95%, 74%)',
		token: '@color-sunset-300',
		type: 'light',
	},
	{
		name: 'Sunset 400',
		hex: '#fac966',
		rgb: 'rgb(250, 201, 102)',
		hsl: 'hsl(40, 94%, 69%)',
		token: '@color-sunset-400',
		type: 'light',
	},
	{
		name: 'Ocean 100',
		hex: '#71cfe6',
		rgb: 'rgb(113, 207, 230)',
		hsl: 'hsl(192, 70%, 67%)',
		token: '@color-ocean-100',
		type: 'light',
	},
	{
		name: 'Ocean 200',
		hex: '#13bcce',
		rgb: 'rgb(19, 188, 206)',
		hsl: 'hsl(186, 83%, 44%)',
		token: '@color-ocean-200',
		type: 'light',
	},
	{
		name: 'Stone 100',
		hex: '#bfbfbf',
		rgb: 'rgb(191, 191, 191)',
		hsl: 'hsl(0, 0%, 75%)',
		token: '@color-stone-100',
		type: 'dark',
	},
	{
		name: 'Stone 200',
		hex: '#a1a1a1',
		rgb: 'rgb(161, 161, 161)',
		hsl: 'hsl(0, 0%, 63%)',
		token: '@color-stone-200',
		type: 'light',
	},
	{
		name: 'Blue 50',
		hex: '#6699cc',
		rgb: 'rgb(102, 153, 204)',
		hsl: 'hsl(210, 50%, 60%)',
		token: '@color-blue-50',
		type: 'light',
	},
	{
		name: 'Blue 100',
		hex: '#1c63a5',
		rgb: 'rgb(28, 99, 165)',
		hsl: 'hsl(209, 71%, 38%)',
		token: '@color-blue-100',
		type: 'dark',
	},
	{
		name: 'Blue 200',
		hex: '#1771c6',
		rgb: 'rgb(23, 113, 198)',
		hsl: 'hsl(209, 79%, 43%)',
		token: '@color-blue-200',
		type: 'dark',
	},
	{
		name: 'Blue 300',
		hex: '#18568f',
		rgb: 'rgb(24, 86, 143)',
		hsl: 'hsl(209, 71%, 33%)',
		token: '@color-blue-300',
		type: 'dark',
	},
	{
		name: 'Blue gray 100',
		hex: '#3e6287',
		rgb: 'rgb(62, 98, 135)',
		hsl: 'hsl(210, 37%, 39%)',
		token: '@color-blue-gray-100',
		type: 'dark',
	},
	{
		name: 'Blue gray 200',
		hex: '#456d96',
		rgb: 'rgb(69, 110, 150)',
		hsl: 'hsl(210, 37%, 43%)',
		token: '@color-blue-gray-200',
		type: 'dark',
	},
	{
		name: 'Blue gray 300',
		hex: '#38597a',
		rgb: 'rgb(56, 89, 122)',
		hsl: 'hsl(210, 37%, 35%)',
		token: '@color-blue-gray-300',
		type: 'dark',
	},
	{
		name: 'Light blue 100',
		hex: '#deeeff',
		rgb: 'rgb(222, 238, 255)',
		hsl: 'hsl(211, 100%, 94%)',
		token: '@color-light-blue-100',
		type: 'light',
	},
	{
		name: 'Light blue 200',
		hex: '#d5e4f5',
		rgb: 'rgb(213, 228, 245)',
		hsl: 'hsl(212, 62%, 90%)',
		token: '@color-light-blue-200',
		type: 'light',
	},
	{
		name: 'Sky blue 100',
		hex: '#53b1fd',
		rgb: 'rgb(83, 177, 253)',
		hsl: 'hsl(207, 98%, 66%)',
		token: '@color-sky-blue-100',
		type: 'light',
	},
	{
		name: 'Sky blue 200',
		hex: '#7cc3fd',
		rgb: 'rgb(124, 195, 253)',
		hsl: 'hsl(207, 97%, 74%)',
		token: '@color-sky-blue-200',
		type: 'light',
	},
	{
		name: 'Sky blue 300',
		hex: '#4ca3ea',
		rgb: 'rgb(76, 163, 234)',
		hsl: 'hsl(207, 79%, 61%)',
		token: '@color-sky-blue-300',
		type: 'light',
	},
	{
		name: 'Steel blue 100',
		hex: '#48709a',
		rgb: 'rgb(72, 112, 154)',
		hsl: 'hsl(211, 36%, 44%)',
		token: '@color-steel-blue-100',
		type: 'dark',
	},
	{
		name: 'Steel blue 200',
		hex: '#4878a8',
		rgb: 'rgb(72, 120, 168)',
		hsl: 'hsl(210, 40%, 47%)',
		token: '@color-steel-blue-200',
		type: 'dark',
	},
	{
		name: 'Steel blue 300',
		hex: '#41668c',
		rgb: 'rgb(65, 102, 140)',
		hsl: 'hsl(210, 37%, 40%)',
		token: '@color-steel-blue-300',
		type: 'dark',
	},
	{
		name: 'Deep teal 100',
		hex: '#034969',
		rgb: 'rgb(3, 73, 105)',
		hsl: 'hsl(199, 94%, 21%)',
		token: '@color-deep-teal-100',
		type: 'dark',
	},
	{
		name: 'Deep teal 200',
		hex: '#022f43',
		rgb: 'rgb(2, 47, 67)',
		hsl: 'hsl(198, 94%, 14%)',
		token: '@color-deep-teal-200',
		type: 'dark',
	},
	{
		name: 'Yellow 100',
		hex: '#FFE900',
		rgb: 'rgb(255, 233, 0)',
		hsl: 'hsl(55, 100%, 50%)',
		token: '@color-yellow-100',
		type: 'light',
	},
	{
		name: 'Green 100',
		hex: '#7FC5A2',
		rgb: 'rgb(127, 197, 162)',
		hsl: 'hsl(150, 37.6%, 63.5%)',
		token: '@color-green-100',
		type: 'light',
	},
	{
		name: 'Green 200',
		hex: '#006E18',
		rgb: 'rgb(0, 110, 24)',
		hsl: 'hsl(133, 100%, 21.6%)',
		token: '@color-dark-green',
		type: 'dark',
	},
	{
		name: 'Forest green 100',
		hex: '#ECFDF3',
		rgb: 'rgb(236, 253, 243)',
		hsl: 'hsl(145, 81%, 96%)',
		token: '@color-badge-100',
		type: 'light',
	},
	{
		name: 'Forest green 200',
		hex: '#ABEFC6',
		rgb: 'rgb(171, 239, 198)',
		hsl: 'hsl(144, 68%, 80%)',
		token: '@color-badge-200',
		type: 'light',
	},
	{
		name: 'Forest green 300',
		hex: '#035030',
		rgb: 'rgb(3, 80, 48)',
		hsl: 'hsl(155, 93%, 16%)',
		token: '@color-badge-300',
		type: 'dark',
	},
	{
		name: 'Raspberry 100',
		hex: '#fdf2fa',
		rgb: 'rgb(253, 242, 250)',
		hsl: 'hsl(316, 73%, 97%)',
		token: '@color-badge-400',
		type: 'light',
	},
	{
		name: 'Raspberry 200',
		hex: '#fcceee',
		rgb: 'rgb(252, 207, 238)',
		hsl: 'hsl(318, 88%, 90%)',
		token: '@color-badge-500',
		type: 'light',
	},
	{
		name: 'Raspberry 300',
		hex: '#8b0e52',
		rgb: 'rgb(139, 14, 82)',
		hsl: 'hsl(327, 82%, 30%)',
		token: '@color-badge-600',
		type: 'dark',
	},
	{
		name: 'Coral 100',
		hex: '#f44336',
		rgb: 'rgb(244, 67, 54)',
		hsl: 'hsl(4, 90%, 58%)',
		token: '@color-coral-100',
		type: 'dark',
	},
	{
		name: 'Coral 200',
		hex: '#ff8178',
		rgb: 'rgb(255, 129, 120)',
		hsl: 'hsl(4, 100%, 74%)',
		token: '@color-coral-200',
		type: 'light',
	},
	{
		name: 'Fuchsia 100',
		hex: '#e91e9b',
		rgb: 'rgb(233, 30, 155)',
		hsl: 'hsl(323, 82%, 52%)',
		token: '@color-fuchsia-100',
		type: 'dark',
	},
	{
		name: 'Fuchsia 200',
		hex: '#ff6cfa',
		rgb: 'rgb(255, 108, 250)',
		hsl: 'hsl(302, 100%, 71%)',
		token: '@color-fuchsia-100',
		type: 'light',
	},
	{
		name: 'Orchid 100',
		hex: '#9c27b0',
		rgb: 'rgb(156, 39, 176)',
		hsl: 'hsl(291, 64%, 42%)',
		token: '@color-orchid-100',
		type: 'dark',
	},
	{
		name: 'Orchid 200',
		hex: '#b83dff',
		rgb: 'rgb(184, 61, 255)',
		hsl: 'hsl(278, 100%, 62%)',
		token: '@color-orchid-100',
		type: 'dark',
	},
	{
		name: 'Violet 100',
		hex: '#673ab7',
		rgb: 'rgb(103, 58, 183)',
		hsl: 'hsl(262, 52%, 47%)',
		token: '@color-violet-100',
		type: 'dark',
	},
	{
		name: 'Violet 200',
		hex: '#be99ff',
		rgb: 'rgb(190, 153, 255)',
		hsl: 'hsl(262, 100%, 80%)',
		token: '@color-violet-200',
		type: 'light',
	},
	{
		name: 'Indigo 100',
		hex: '#3f51b5',
		rgb: 'rgb(63, 81, 181)',
		hsl: 'hsl(231, 48%, 48%)',
		token: '@color-indigo-100',
		type: 'dark',
	},
	{
		name: 'Indigo 200',
		hex: '#b1bbf2',
		rgb: 'rgb(177, 187, 242)',
		hsl: 'hsl(231, 71%, 82%)',
		token: '@color-indigo-200',
		type: 'light',
	},
	{
		name: 'Azure 100',
		hex: '#2196f3',
		rgb: 'rgb(33, 150, 243)',
		hsl: 'hsl(207, 90%, 54%)',
		token: '@color-azure-100',
		type: 'dark',
	},
	{
		name: 'Azure 200',
		hex: '#69bcff',
		rgb: 'rgb(105, 188, 255)',
		hsl: 'hsl(207, 100%, 71%)',
		token: '@color-azure-200',
		type: 'light',
	},
	{
		name: 'Cerulean 100',
		hex: '#03a9f4',
		rgb: 'rgb(2, 166, 242)',
		hsl: 'hsl(207, 100%, 71%)',
		token: '@color-cerulean-100',
		type: 'dark',
	},
	{
		name: 'Cerulean 200',
		hex: '#9ee1ff',
		rgb: 'rgb(158, 224, 255)',
		hsl: 'hsl(199, 100%, 81%)',
		token: '@color-cerulean-200',
		type: 'light',
	},
	{
		name: 'Turquoise 100',
		hex: '#00bcd4',
		rgb: 'rgb(0, 188, 212)',
		hsl: 'hsl(187, 100%, 42%)',
		token: '@color-turquoise-100',
		type: 'dark',
	},
	{
		name: 'Turquoise 200',
		hex: '#00e0fc',
		rgb: 'rgb(0, 224, 252)',
		hsl: 'hsl(187, 100%, 49%)',
		token: '@color-turquoise-200',
		type: 'light',
	},
	{
		name: 'Golden 100',
		hex: '#ffeb3b',
		rgb: 'rgb(255, 235, 59)',
		hsl: 'hsl(54, 100%, 62%)',
		token: '@color-golden-100',
		type: 'light',
	},
	{
		name: 'Golden 200',
		hex: '#faec71',
		rgb: 'rgb(250, 236, 113)',
		hsl: 'hsl(54, 93%, 71%)',
		token: '@color-golden-200',
		type: 'light',
	},
	{
		name: 'Amber 100',
		hex: '#ffc107',
		rgb: 'rgb(255, 193, 5)',
		hsl: 'hsl(45, 100%, 51%)',
		token: '@color-amber-100',
		type: 'light',
	},
	{
		name: 'Amber 200',
		hex: '#ffce3b',
		rgb: 'rgb(255, 207, 61)',
		hsl: 'hsl(45, 100%, 62%)',
		token: '@color-amber-200',
		type: 'light',
	},
	{
		name: 'Tangerine 100',
		hex: '#ff9800',
		rgb: 'rgb(255, 152, 0)',
		hsl: 'hsl(36, 100%, 50%)',
		token: '@color-tangerine-100',
		type: 'dark',
	},
	{
		name: 'Tangerine 200',
		hex: '#ffba54',
		rgb: 'rgb(255, 186, 84)',
		hsl: 'hsl(36, 100%, 66%)',
		token: '@color-tangerine-200',
		type: 'light',
	},
	{
		name: 'Ember 100',
		hex: '#ff5722',
		rgb: 'rgb(255, 87, 36)',
		hsl: 'hsl(14, 100%, 57%)',
		token: '@color-ember-100',
		type: 'dark',
	},
	{
		name: 'Ember 200',
		hex: '#ff7f57',
		rgb: 'rgb(255, 126, 87)',
		hsl: 'hsl(14, 100%, 67%)',
		token: '@color-ember-200',
		type: 'light',
	},
	{
		name: 'Ashen gray 100',
		hex: '#858383',
		rgb: 'rgb(134, 131, 131)',
		hsl: 'hsl(0, 1%, 52%)',
		token: '@color-ashen-gray-100',
		type: 'light',
	},
	{
		name: 'Ashen gray 200',
		hex: '#9e9e9e',
		rgb: 'rgb(158, 158, 158)',
		hsl: 'hsl(0, 0%, 62%)',
		token: '@color-ashen-gray-200',
		type: 'light',
	},
	{
		name: 'Aqua 100',
		hex: '#7fd3fa',
		rgb: 'rgb(128, 211, 250)',
		hsl: 'hsl(199, 92%, 74%)',
		token: '@color-aqua-100',
		type: 'dark',
	},
	{
		name: 'Aqua 200',
		hex: '#25abea',
		rgb: 'rgb(200, 234, 249)',
		hsl: 'hsl(198, 81%, 88%)',
		token: '@color-aqua-200',
		type: 'light',
	},
	{
		name: 'Charcoal Blue 100',
		hex: '#c7eaf9',
		rgb: 'rgb(37, 171, 233)',
		hsl: 'hsl(199, 82%, 53%)',
		token: '@color-charcoal-blue-100',
		type: 'light',
	},
	{
		name: 'Charcoal Blue 200',
		hex: '#4184a3',
		rgb: 'rgb(65, 133, 164)',
		hsl: 'hsl(199, 43%, 45%)',
		token: '@color-charcoal-blue-200',
		type: 'light',
	},
	{
		name: 'Emerald 100',
		hex: '#4caf50',
		rgb: 'rgb(76, 174, 79)',
		hsl: 'hsl(122, 39%, 49%)',
		token: '@color-emerald-100',
		type: 'dark',
	},
	{
		name: 'Emerald 200',
		hex: '#67f06d',
		rgb: 'rgb(102, 240, 109)',
		hsl: 'hsl(123, 82%, 67%)',
		token: '@color-emerald-200',
		type: 'light',
	},
	{
		name: 'Lime 100',
		hex: '#8bc34a',
		rgb: 'rgb(139, 195, 75)',
		hsl: 'hsl(88, 50%, 53%)',
		token: '@color-lime-100',
		type: 'dark',
	},
	{
		name: 'Lime 200',
		hex: '#adf25c',
		rgb: 'rgb(171, 242, 90)',
		hsl: 'hsl(88, 85%, 65%)',
		token: '@color-lime-200',
		type: 'light',
	},
	{
		name: 'Citrus Lime 100',
		hex: '#cddc39',
		rgb: 'rgb(203, 220, 56)',
		hsl: 'hsl(66, 70%, 54%)',
		token: '@color-citrus-lime-100',
		type: 'dark',
	},
	{
		name: 'Citrus Lime 200',
		hex: '#eeff42',
		rgb: 'rgb(239, 255, 66)',
		hsl: 'hsl(65, 100%, 63%)',
		token: '@color-citrus-lime-200',
		type: 'light',
	},
	{
		name: 'Teal 100',
		hex: '#009688',
		rgb: 'rgb(0, 148, 133)',
		hsl: 'hsl(174, 100%, 29%)',
		token: '@color-teal-100',
		type: 'dark',
	},
	{
		name: 'Teal 200',
		hex: '#00edd7',
		rgb: 'hsl(174, 100%, 46%)',
		hsl: 'hsl(174, 100%, 46%)',
		token: '@color-teal-200',
		type: 'light',
	},
	{
		name: 'Dark Yellow',
		hex: '#C6B539',
		rgb: 'rgb(198, 181, 57)',
		hsl: 'hsl(355, 100%, 76.1%)',
		token: '@color-dark-yellow',
		type: 'light',
	},
	{
		name: 'Light Violet',
		hex: '#D6B9FF',
		rgb: 'rgb(214, 185, 255)',
		hsl: 'hsl(265, 100%, 86.3%)',
		token: '@color-light-violet',
		type: 'light',
	},
	{
		name: 'Light Blue',
		hex: '#6699CC',
		rgb: 'rgb(102, 153, 204)',
		hsl: 'hsl(210, 50%, 60%)',
		token: '@color-light-blue',
		type: 'dark',
	},
	{
		name: 'Medium Blue',
		hex: '#3992C6',
		rgb: 'rgb(57, 146, 198)',
		hsl: 'hsl(202, 55.3%, 50%)',
		token: '@color-medium-blue',
		type: 'dark',
	}

];
