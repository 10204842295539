// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const severityStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Empty Dot',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Critical Dot',
		property: 'background-color',
		value: '8c2000',
		isColorTile: true,
	},
	{
		name: 'High Dot',
		property: 'background-color',
		value: 'f58509',
		isColorTile: true,
	},
	{
		name: 'Moderate Dot',
		property: 'background-color',
		value: 'fac966',
		isColorTile: true,
	},
	{
		name: 'Low Dot',
		property: 'background-color',
		value: '13bcce',
		isColorTile: true,
	},
];

export const severityStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Empty Dot',
		property: 'border-color',
		value: '949494',
		isColorTile: true,
	},
	{
		name: 'Critical Dot',
		property: 'background-color',
		value: 'fe5666',
		isColorTile: true,
	},
	{
		name: 'High Dot',
		property: 'background-color',
		value: 'ff9c68',
		isColorTile: true,
	},
	{
		name: 'Moderate Dot',
		property: 'background-color',
		value: 'fcdf7d',
		isColorTile: true,
	},
	{
		name: 'Low Dot',
		property: 'background-color',
		value: '71cfe6',
		isColorTile: true,
	},
];

export const severityStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Text - Number (count variation)',
		property: 'font-size',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: 'Text - Body (count variation)',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const severityStylesStructure = [
	{
		name: 'Text',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Dot',
		property: 'border-width',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Text - Number (count variation)',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];
