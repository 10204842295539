// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: modalStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const modalStylesDefaultTheme = [
	{
		name: 'Title text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Body text',
		property: 'color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: '',
		property: 'box-shadow',
		value: '000000',
		isColorTile: true,
	},
];

export const modalStylesDarkTheme = [
	{
		name: 'Title text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Body text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '3b3b3b',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '595959',
		isColorTile: true,
	},
	{
		name: '',
		property: 'box-shadow',
		value: '000000',
		isColorTile: true,
	},
];
export const modalStylesTypography = [
	{
		name: 'Title text (H2)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '22px',
		isColorTile: false,
	},
	{
		name: 'Body text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];
export const modalStylesTypographyButton = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];
export const modalStylesStructure = [
	{
		name: 'Container',
		property: 'width',
		value: '450px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '150px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'box-shadow',
		value: '0px 2px 4px 0px rgba(0,0,0,0.1) (2px vertical length, 0px horizontal length, 4px blur, 0px spread, 10% opacity, black color)',
		isColorTile: false,
	},
	{
		name: 'Text (all text)',
		property: 'text-align',
		value: 'left',
		isColorTile: false,
	},
	{
		name: 'Title text',
		property: 'margin-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Body text',
		property: 'margin-bottom',
		value: '16px',
		isColorTile: false,
	},
];

export const modalStylesStructureButton = [
	{
		name: 'Container',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'cursor',
		value: 'pointer',
		isColorTile: false,
	},
	{
		name: 'Container outline: focus',
		property: 'solid',
		value: '2px',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const modalStylesDefaultThemeOld = [
	{
		name: 'Body Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
];

export const modalStylesDarkThemeOld = [
	{
		name: 'Body Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
];

export const modalStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Header Text',
		property: 'font-family',
		value: '"Open Sans Bold", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
];

export const modalStylesStructureOld = [
	{
		name: 'Border (Dark Theme)',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Modal Body (Dialog Modal)',
		property: 'min-height',
		value: '150px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '450px',
		isColorTile: false,
	},
	{
		name: 'Full Screen Right Aligned Container',
		property: 'height',
		value: '100%',
		isColorTile: false,
	},
];
