// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const buttonStylesDefaultThemePrimary = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc ',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: 'fcfcfc ',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '18568f',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc ',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: 'fcfcfc ',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '3e6287',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '456d96',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '456d96',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '38597a',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeDanger = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc ',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: 'fcfcfc ',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeGhost = [
	{
		name: 'Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '18568f',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - Transparent',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Container:hover',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: 'd5e4f5',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '18568f',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeText = [
	{
		name: 'Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '18568f',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - transparent',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: 'd5e4f5',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeTextSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: '3e6287',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '3e6287',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '38597a',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - transparent',
		isColorTile: false,
	},
	{
		name: 'Container: hover / focus',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: 'd5e4f5',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeTextIcon = [
	{
		name: 'Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '18568f',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Icon: hover / focus',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Icon: active / pressed',
		property: 'color',
		value: '18568f',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: 'd5e4f5',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeIcon = [
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Icon: focus',
		property: 'outline-color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemePrimary = [
	{
		name: 'Text',
		property: 'color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '4ca3ea',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '48709a',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '4878a8',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '4878a8',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '41668c',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeDanger = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeGhost = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '4ca3ea',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'border-color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'None - Transparent',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background',
		value: '034969',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '022f43',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4ca3ea',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeText = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '4ca3ea',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - transparent',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '022f43',
		isColorTile: true,
	},
	
];

export const buttonStylesDarkThemeTextSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: 'd5e4f5',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - transparent',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '022f43',
		isColorTile: true,
	},
	
];

export const buttonStylesDarkThemeTextIcon = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Text: active / pressed',
		property: 'color',
		value: '4ca3ea',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Icon: hover / focus',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Icon: active / pressed',
		property: 'color',
		value: '4ca3ea',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - transparent',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: active / pressed',
		property: 'background-color',
		value: '022f43',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeIcon = [
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Icon: focus',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	}
];

export const buttonStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '24px',
		isColorTile: false,
	},
];

export const buttonStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'cursor',
		value: 'pointer',
		isColorTile: false,
	},
	{
		name: 'Container Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
];
export const buttonStylesStructureIcon = [
	{
		name: 'Height',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Width',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
];
