import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

import {
	datePickerStylesDefaultTheme,
	datePickerStylesDarkTheme,
	datePickerStylesTypography,
	datePickerStylesStructure,
	datePickerStylesDefaultPrimaryTheme,
	datePickerStylesDefaultSecondaryTheme,
	datePickerStylesDarkPrimaryTheme,
	datePickerStylesDarkSecondaryTheme,
	datePickerStylesTextInputStructure,
	datePickerStylesButtonTextStructure
} from './date-picker-styles';
import {
	textFieldStylesTypography
} from './../text-field/text-field-styles';
import {
	buttonStylesDarkThemeText,
	buttonStylesDarkThemeTextSecondary,
	buttonStylesDefaultThemeText,
	buttonStylesDefaultThemeTextSecondary,
	buttonStylesTypography
} from './../button/button-styles';
import { AdaptiveMode, FormatSettings } from "@progress/kendo-angular-dateinputs";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { shCheck, shCircleQuestion, shXmark } from '@beyondtrust/shield-icons';
import { ChangelogService } from 'src/app/services/changelog.service';

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.less'],
})
export class DatePickerComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = datePickerStylesDefaultTheme;
	public stylesDefaultPrimary = buttonStylesDefaultThemeText;
	public stylesDefaultSecondary = buttonStylesDefaultThemeTextSecondary;
	public stylesDark = datePickerStylesDarkTheme;
	public stylesDarkPrimary = buttonStylesDarkThemeText;
	public stylesDarkSecondary = buttonStylesDarkThemeTextSecondary;
	public stylesTypography = datePickerStylesTypography;
	public stylesTextFieldTypography = textFieldStylesTypography;
	public stylesButtonTextTypography = buttonStylesTypography;
	public stylesStructure = datePickerStylesStructure;
	public stylesTextInputStructure = datePickerStylesTextInputStructure;
	public stylesButtonTextStructure = datePickerStylesButtonTextStructure;
	public doThisIcon = shCheck;
	public xMarkIcon = shXmark;

	public questionCircleIcon = shCircleQuestion;
	public dateValue = new Date(Date.now());
	public form: FormGroup = new FormGroup({
		birthDate: new FormControl(this.dateValue, Validators.required),
	});
	public format: FormatSettings = {
		displayFormat: 'MMMM, dd, yyyy',
		inputFormat: 'MM/dd/yyyy',
	};
	public dateMin: Date = new Date(2021, 0, 1);
	public dateMax: Date = new Date(2025, 12, 31);
	public adaptiveMode: AdaptiveMode = "auto";
	public disabled = false;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
				// {
				// 	name: 'API',
				// 	value: 'api',
				// },
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Text field',
					value: 'text-field',
				},
				{
					name: 'Calendar pop-up',
					value: 'calendar-popup',
				},
				{
					name: 'Input masking',
					value: 'masking',
				},
				{
					name: 'Error',
					value: 'error',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
				{
					name: 'Help text',
					value: 'help-text',
				},
				{
					name: 'Instructional text',
					value: 'instructional-text',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: true,
			children: [],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Date format',
					value: 'date-format',
				},
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService,
		public changelogService: ChangelogService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public get dateControl(): AbstractControl {
		return this.form.controls.birthDate;
	}

	public validateDate(): void {
		//check for outside date range errors
		if (!this.dateControl.valid) {
			if ("minError" in this.dateControl.errors) {
				this.dateValue = this.form.controls.birthDate.errors.minError.minValue;
			} else if("maxError" in this.dateControl.errors) {
				this.dateValue = this.form.controls.birthDate.errors.maxError.maxValue;
			}
		}
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
		switch (this.currLive) {
			case "disabled":
				this.disabled = true;
				break;
			default:
				this.disabled = false;
				break;
		}
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public datePickerHTML = `
<kendo-formfield>
	<kendo-label [for]="birthDate" text="Birth Date"></kendo-label>
	<kendo-datepicker
		#birthDate
		required
		formControlName="birthDate"
		[disabled]="disabled"
		[(ngModel)]="dateValue"
		[format]="format"
		[adaptiveMode]="adaptiveMode"
		[min]="dateMin"
		[max]="dateMax"
		(blur)="validateDate()"
		(open)="validateDate()"
		[incompleteDateValidation]="true"
	>
	<ng-template
			kendoCalendarNavigationItemTemplate
			let-title
			let-date="date"
			let-view="activeView"
		>
			{{ view === "month" && date.getMonth() === 0 ? "Jan" : title }}
	</ng-template>
	</kendo-datepicker>
	<kendo-formhint>MM/DD/YYYY</kendo-formhint>
	<kendo-formerror *ngIf="dateControl.errors?.incompleteDate">
		Date must not be incomplete
	</kendo-formerror>
	<kendo-formerror *ngIf="dateControl.errors?.minError || dateControl.errors?.maxError">
		The selected date is outside the allowed period
	</kendo-formerror>
</kendo-formfield>`;

	public datePickerTS = `
import { AdaptiveMode, FormatSettings } from "@progress/kendo-angular-dateinputs";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { shCheck, shCircleQuestion, shXmark } from '@beyondtrust/shield-icons';

public questionCircleIcon = shCircleQuestion;
public dateValue = new Date(Date.now());
public form: FormGroup = new FormGroup({
	birthDate: new FormControl(this.dateValue, Validators.required),
});
public format: FormatSettings = {
	displayFormat: 'MMMM dd, yyyy',
	inputFormat: 'MM/dd/yyyy',
};
public dateMin: Date = new Date(2021, 0, 1);
public dateMax: Date = new Date(2025, 12, 31);
public adaptiveMode: AdaptiveMode = "auto";
public disabled = false;

public get dateControl(): AbstractControl {
	return this.form.controls.birthDate;
}

public validateDate(): void {
	if (!this.dateControl.valid) {
		if ("minError" in this.dateControl.errors) {
			this.dateValue = this.form.controls.birthDate.errors.minError.minValue;
		} else if("maxError" in this.dateControl.errors) {
			this.dateValue = this.form.controls.birthDate.errors.maxError.maxValue;
		}
	}
}`;
}
