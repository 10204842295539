// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const barChartStylesDefaultTheme = [
	{
		name: 'Bar Color',
		property: 'background-color',
		value: 'f44336',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'e91e9b',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '9c27b0',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '673ab7',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '3f51b5',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '2196f3',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'ffeb3b',
		isColorTile: true,
	},
	{
		name: 'Grid Lines',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
];

export const barChartStylesDefaultThemeSeverity = [
	{
		name: 'Bar Color - Critical',
		property: 'background-color',
		value: '8c2000',
		isColorTile: true,
	},
	{
		name: 'Bar Color - High',
		property: 'background-color',
		value: 'f58509',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Moderate',
		property: 'background-color',
		value: 'fac966',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Low',
		property: 'background-color',
		value: '13bcce',
		isColorTile: true,
	},
];

export const barChartStylesDefaultThemeStatus = [
	{
		name: 'Bar Color - Success',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Warning',
		property: 'background-color',
		value: 'FFE900',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Error',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Informational',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const barChartStylesDarkTheme = [
	{
		name: 'Bar Color',
		property: 'background-color',
		value: 'ff8178',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'ff6cfa',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'b83dff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'be99ff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'b1bbf2',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '69bcff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'faec71',
		isColorTile: true,
	},
	{
		name: 'Grid Lines',
		property: 'border-color',
		value: '595959',
		isColorTile: true,
	},
];

export const barChartStylesDarkThemeSeverity = [
	{
		name: 'Bar Color - Critical',
		property: 'background-color',
		value: 'fe5666',
		isColorTile: true,
	},
	{
		name: 'Bar Color - High',
		property: 'background-color',
		value: 'ff9c68',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Moderate',
		property: 'background-color',
		value: 'fcdf7d',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Low',
		property: 'background-color',
		value: '71cfe6',
		isColorTile: true,
	},
];

export const barChartStylesDarkThemeStatus = [
	{
		name: 'Bar Color - Success',
		property: 'background-color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Warning',
		property: 'background-color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Error',
		property: 'background-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Informational',
		property: 'background-color',
		value: '6699CC',
		isColorTile: true,
	},
];

export const barChartStylesTypography = [
	{
		name: 'Axis Label Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Chart Legend Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const barChartStylesStructure = [
	{
		name: 'Container Outline: focus',
		property: 'solid',
		value: '2px',
		isColorTile: false,
	},
];
