import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	barChartStylesDarkTheme,
	barChartStylesDarkThemeSeverity,
	barChartStylesDarkThemeStatus,
	barChartStylesDefaultTheme,
	barChartStylesDefaultThemeSeverity,
	barChartStylesDefaultThemeStatus,
	barChartStylesTypography,
	barChartStylesStructure,
} from './bar-chart-styles';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { SVGIcon } from '@beyondtrust/shield-icons';
import { Series, ValueAxis, SeriesNotes, SeriesType, CategoryAxisLabels, LegendItemVisualArgs  } from '@progress/kendo-angular-charts';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

import {
	Element,
	geometry,
	Group,
	Path,
	Rect as RectShape,
	Text,
  } from "@progress/kendo-drawing";
import { tooltipStylesDarkTheme, tooltipStylesDefaultTheme, tooltipStylesStructure, tooltipStylesTypography } from '../tooltip/tooltip-styles';
  const { Point, Rect, Size } = geometry;
  
enum DataTypes {
	default = 'default',
	severity = 'severity',
	status = 'status',
}

export type DataModel = {
	categoryName: string;
	labelName: string;
	data: number;
	colorString: string;
};

@Component({
	selector: 'app-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.less'],
})
export class BarChartComponent implements OnDestroy {
	// @ViewChild("tooltipContainer", { read: ViewContainerRef, static: true }) public popupContainer: ViewContainerRef;
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public selectedValue: string = 'vertical';
	public selectedBarType: SeriesType = 'column';
	public stackFlag: boolean = false;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Legend visibility',
					value: 'legend-visibility',
				},
				{
					name: 'Tooltip',
					value: 'tooltip',
				},
				{
					name: 'Pan and zoom',
					value: 'pan-and-zoom',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Horizontal and vertical',
					value: 'horizontal-and-vertical',
				},
				{
					name: 'Stacked and grouped',
					value: 'stacked-and-grouped',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general-use',
				},
				{
					name: 'Severity',
					value: 'severity',
				},
				{
					name: 'Status',
					value: 'status',
				},
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	// Imported Style Variables
	public stylesDefault = barChartStylesDefaultTheme;
	public stylesDefaultSeverity = barChartStylesDefaultThemeSeverity;
	public stylesDefaultStatus = barChartStylesDefaultThemeStatus;
	public stylesDark = barChartStylesDarkTheme;
	public stylesDarkSeverity = barChartStylesDarkThemeSeverity;
	public stylesDarkStatus = barChartStylesDarkThemeStatus;
	public stylesTypography = barChartStylesTypography;
	public stylesStructure = barChartStylesStructure;

	// Imported Toolips Styles Variables
	public stylesTooltipDefault = tooltipStylesDefaultTheme;
	public stylesTooltipDark = tooltipStylesDarkTheme;
	public stylesTooltipTypography = tooltipStylesTypography;
	public stylesTooltipStructure = tooltipStylesStructure;

	icons = ShieldIcons;
	public star: SVGIcon = ShieldIcons.shStar;
	public dataTypes = DataTypes;
	public selectedDataType = this.dataTypes.default;
	
	public defaultDarkSeries: DataModel[] = [
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-01',
		  data: 92,
		  colorString: 'var(--color-chart-palette-1)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-02',
		  data: 140,
		  colorString: 'var(--color-chart-palette-2)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-03',
		  data: 199,
		  colorString: 'var(--color-chart-palette-3)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-04',
		  data: 420,
		  colorString: 'var(--color-chart-palette-4)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-05',
		  data: 500,
		  colorString: 'var(--color-chart-palette-5)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-06',
		  data: 295,
		  colorString: 'var(--color-chart-palette-6)'
		},
		{
			categoryName: 'Category 1',
			labelName: 'Data-07',
			data: 295,
			colorString: 'var(--color-chart-palette-9)'
		}
	];

	public severityLightSeries: DataModel[] = [
		{
			categoryName: 'Category 1',
			labelName: 'Critical',
			data: 100,
			colorString: 'var(--color-severity-critical)'
		},
		{
			categoryName: 'Category 1',
			labelName: 'High',
			data: 150,
			colorString: 'var(--color-severity-high)'
		},
		{
			categoryName: 'Category 1',
			labelName: 'Moderate',
			data: 200,
			colorString: 'var(--color-severity-moderate)'
		},
		{
			categoryName: 'Category 1',
			labelName: 'Low',
			data: 300,
			colorString: 'var(--color-severity-low)'
		}
	];
	
	public severityDarkSeries: DataModel[] = [
		{
		  categoryName: 'Category 1',
		  labelName: 'Critical',
		  data: 100,
		  colorString: '#D6B9FF'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'High',
		  data: 150,
		  colorString: '#F39681'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Moderate',
		  data: 200,
		  colorString: '#F3C781'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Low',
		  data: 300,
		  colorString: '#71CFE6'
		}
	];
	
	public statusLightSeries: DataModel[] = [
		{
		  categoryName: 'Category 1',
		  labelName: 'Success',
		  data: 450,
		  colorString: 'var(--color-status-success)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Warning',
		  data: 350,
		  colorString: 'var(--color-status-warning)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Error',
		  data: 100,
		  colorString: 'var(--color-status-error)'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Informational',
		  data: 100,
		  colorString: 'var(--color-status-info)'
		}
	];

	public statusDarkSeries: DataModel[] = [
		{
		  categoryName: 'Category 1',
		  labelName: 'Success',
		  data: 450,
		  colorString: '#7FC5A2'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Warning',
		  data: 350,
		  colorString: '#F6C664'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Error',
		  data: 100,
		  colorString: '#FF8593'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Informational',
		  data: 100,
		  colorString: '#6699CC'
		}
	];

	public groupedData = groupBy(this.defaultDarkSeries, [{ field: 'labelName' }]) as GroupResult[];

	public groupedSeverityLightData = groupBy(this.severityLightSeries, [{ field: 'labelName' }]) as GroupResult[];

	public groupedStatusLightData = groupBy(this.statusLightSeries, [{ field: 'labelName' }]) as GroupResult[];

	public notes: SeriesNotes = {
		label: {
			position: 'outside',
			font: '12px Arial'
		},
		line: {
			width: 0
		},
		icon: {
			visible: false
		},
		position: 'bottom'
	};

	public labelOptions: CategoryAxisLabels = {
		margin: {
			top: 30
		}
	};

	public valueAxes: ValueAxis[] = [
	{
		name: 'quantity',
		title: { text: 'Quantity', font: '12px Arial' }
	}
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}
	
	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changePalette(str?: string): void {
		switch (str) {
			case 'dataStatus': {
				this.selectedDataType = this.dataTypes.status;
				break;
			}
			case 'dataSeverity': {
				this.selectedDataType = this.dataTypes.severity;
				break;
			}
			default: {
				this.selectedDataType = this.dataTypes.default;
				break;
			}
		}
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
		switch (this.currLive) {
			case 'severity':
				this.changePalette('dataSeverity');
				break;
			case 'status':
				this.changePalette('dataStatus');
				break;
			default:
				this.changePalette();
				break;
		}
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}


	public changeBarOption(event): any {
		this.selectedValue = event.target.value;
		switch (this.selectedValue) {
		  case 'stacked': {
			this.selectedBarType = 'column';
			this.stackFlag = true;
			this.notes = {
				label: {
					position: 'inside',
					font: '12px Arial',
					visible: false
				},
				line: {
					width: 0
				},
				icon: {
					visible: false
				},
				position: 'top'
			};
			this.labelOptions = {
				margin: {
					top: 0
				}
			};
			break;
		  }
		  case 'horizontal': {
			this.selectedBarType = 'bar';
			this.stackFlag = false;
			this.notes = {
				label: {
					position: 'inside',
					font: '12px Arial'
				},
				line: {
					width: 0
				},
				icon: {
					visible: false
				},
				position: 'left'
			};
			this.labelOptions = {
				margin: {
					left: 90
				},
				rotation: -90
			};
			break;
		  }
		  default: {
			this.selectedBarType = 'column';
			this.stackFlag = false;
			this.notes = {
				label: {
					position: 'outside',
					font: '12px Arial',
				},
				line: {
					width: 0
				},
				icon: {
					visible: false
				},
				position: 'bottom'
			};
			this.labelOptions = {
				margin: {
					top: 30
				}
				
			};
			break;
		  }
		}
	}

	public legendVisual(args: LegendItemVisualArgs): Element {
		const pathColor = args.series.data[0].colorString;
		const rectOptions = {
		  stroke: {
			color: pathColor,
			width: 1
		  },
		  fill: {
			color: pathColor
		  }
		};
		const rectGeometry = new Rect(new Point(0, 0), new Size(25, 10));
		const rect: RectShape = new RectShape(rectGeometry, rectOptions);
		const lineThrough = new Path({
		  stroke: {
			color: args.options.labels.color,
			width: 3
		  }
		});
		const labelText1 = args.series.name;
		const labelFont = args.options.labels.font;
		const fontColor = args.options.labels.color;
		const textOptions = {
		  font: labelFont,
		  fill: { color: fontColor }
		};
		const text1 = new Text(labelText1, new Point(30, -5), textOptions);
		const textBox1 = text1.bbox();
		const labelLength = textBox1.size.width;
		const group = new Group();
		group.append(rect, text1);
		if (!args.active) {
		  lineThrough.moveTo(30, 5).lineTo(labelLength, 5).close();
		  group.append(rect, text1, lineThrough);
		  group.opacity(0.5);
		}
		return group;
	  }

	public VerticalBarChartHTML: any = `
<kendo-chart
[transitions]="true"
[pannable]="true"
[zoomable]="true"
[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
>
	<kendo-chart-value-axis>
		<kendo-chart-value-axis-item *ngFor="let item of valueAxes" [name]="item.name" [title]="item.title"> </kendo-chart-value-axis-item>
	</kendo-chart-value-axis>
	<kendo-chart-category-axis>
		<kendo-chart-category-axis-item [labels]="labelOptions"> </kendo-chart-category-axis-item>
	</kendo-chart-category-axis>
	<kendo-chart-legend position="right">
		<kendo-chart-legend-item [visual]="legendVisual" cursor="pointer">
		</kendo-chart-legend-item>
	</kendo-chart-legend>
	<kendo-chart-series>
		<kendo-chart-series-item
		*ngFor="let groupedResult of groupedData"
		[data]="groupedResult.items"
		[name]="groupedResult.value"
		field="data"
		categoryField="categoryName"
		noteTextField="labelName"
		colorField="colorString"
		[notes]="notes"
		type="column"
		>
			<kendo-chart-series-item-tooltip>
				<ng-template let-category="category" let-value="value" let-series="series">
					<div class="toolTip">
						<div>{{ series.name }}</div>
						<div>
						{{ value }}
						</div>
					</div>
				</ng-template>
			</kendo-chart-series-item-tooltip>
		</kendo-chart-series-item>
	</kendo-chart-series>
</kendo-chart>
`;
	public VerticalBarChartTs: any = `
public defaultDarkSeries: DataModel[] = [
	{
		categoryName: 'Category 1',
		labelName: 'Data-01',
		data: 92,
		colorString: 'var(--color-chart-palette-1)'
	},
	{
		categoryName: 'Category 1',
		labelName: 'Data-02',
		data: 140,
		colorString: 'var(--color-chart-palette-2)'
	},
	{
		categoryName: 'Category 1',
		labelName: 'Data-03',
		data: 199,
		colorString: 'var(--color-chart-palette-3)'
	},
	{
		categoryName: 'Category 1',
		labelName: 'Data-04',
		data: 420,
		colorString: 'var(--color-chart-palette-4)'
	},
	{
		categoryName: 'Category 1',
		labelName: 'Data-05',
		data: 500,
		colorString: 'var(--color-chart-palette-5)'
	},
	{
		categoryName: 'Category 1',
		labelName: 'Data-06',
		data: 295,
		colorString: 'var(--color-chart-palette-6)'
	},
	{
		categoryName: 'Category 1',
		labelName: 'Data-07',
		data: 295,
		colorString: 'var(--color-chart-palette-9)'
	}
];

public groupedData = groupBy(this.defaultDarkSeries, [{ field: 'labelName' }]) as GroupResult[];

public valueAxes: ValueAxis[] = [
	{
		name: 'quantity',
		title: { text: 'Quantity', font: '12px Arial' }
	}
];

public labelOptions: CategoryAxisLabels = {
	margin: {
		top: 30
	}
};

public notes: SeriesNotes = {
	label: {
		position: 'outside',
		font: '12px Arial'
	},
	line: {
		width: 0
	},
	icon: {
		visible: false
	},
	position: 'bottom'
};

public legendVisual(args: LegendItemVisualArgs): Element {
	const pathColor = args.series.data[0].colorString;
	const rectOptions = {
		stroke: {
			color: pathColor,
			width: 1,
		},
		fill: {
			color: pathColor,
		},
	};
	const rectGeometry = new Rect(new Point(0, 0), new Size(25, 10));
	const rect: RectShape = new RectShape(rectGeometry, rectOptions);
	const lineThrough = new Path({
		stroke: {
			color: args.options.labels.color,
			width: 3,
		},
	});
	const labelText1 = args.series.name;
	const labelFont = args.options.labels.font;
	const fontColor = args.options.labels.color;
	const textOptions = {
		font: labelFont,
		fill: { color: fontColor },
	};
	const text1 = new Text(labelText1, new Point(30, -5), textOptions);
	const textBox1 = text1.bbox();
	const labelLength = textBox1.size.width;
	const group = new Group();
	group.append(rect, text1);
	if (!args.active) {
		lineThrough.moveTo(30, 5).lineTo(labelLength, 5).close();
		group.append(rect, text1, lineThrough);
	}
	return group;
}
`;
	public HorizontalBarChartHTML: any = `
<kendo-chart
[transitions]="true"
[pannable]="true"
[zoomable]="true"
[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
>
	<kendo-chart-value-axis>
		<kendo-chart-value-axis-item *ngFor="let item of valueAxes" [name]="item.name" [title]="item.title"> </kendo-chart-value-axis-item>
	</kendo-chart-value-axis>
	<kendo-chart-category-axis>
		<kendo-chart-category-axis-item [labels]="labelOptions"> </kendo-chart-category-axis-item>
	</kendo-chart-category-axis>
	<kendo-chart-legend position="right">
		<kendo-chart-legend-item [visual]="legendVisual" cursor="pointer">
		</kendo-chart-legend-item>
	</kendo-chart-legend>
	<kendo-chart-series>
		<kendo-chart-series-item
		*ngFor="let groupedResult of groupedData"
		[data]="groupedResult.items"
		[name]="groupedResult.value"
		field="data"
		categoryField="categoryName"
		noteTextField="labelName"
		colorField="colorString"
		[notes]="notes"
		type="bar"
		>
			<kendo-chart-series-item-tooltip>
				<ng-template let-category="category" let-value="value" let-series="series">
					<div class="toolTip">
						<div>{{ series.name }}</div>
						<div>
						{{ value }}
						</div>
					</div>
				</ng-template>
			</kendo-chart-series-item-tooltip>
		</kendo-chart-series-item>
	</kendo-chart-series>
</kendo-chart>
`;
	public HorizontalBarChartTs: any = `
public labelOptions: CategoryAxisLabels = {
	margin: {
		left: 90
	},
	rotation: -90
};

public notes: SeriesNotes = {
	label: {
		position: 'inside',
		font: '12px Arial'
	},
	line: {
		width: 0
	},
	icon: {
		visible: false
	},
	position: 'left'
};
`;
	public StackedBarChartHTML: any = `
<kendo-chart
[transitions]="true"
[pannable]="true"
[zoomable]="true"
[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
>
	<kendo-chart-value-axis>
		<kendo-chart-value-axis-item *ngFor="let item of valueAxes" [name]="item.name" [title]="item.title"> </kendo-chart-value-axis-item>
	</kendo-chart-value-axis>
	<kendo-chart-category-axis>
		<kendo-chart-category-axis-item [labels]="labelOptions"> </kendo-chart-category-axis-item>
	</kendo-chart-category-axis>
	<kendo-chart-legend position="right">
		<kendo-chart-legend-item [visual]="legendVisual" cursor="pointer">
		</kendo-chart-legend-item>
	</kendo-chart-legend>
	<kendo-chart-series>
		<kendo-chart-series-item
		*ngFor="let groupedResult of groupedData"
		[data]="groupedResult.items"
		[name]="groupedResult.value"
		field="data"
		categoryField="categoryName"
		noteTextField="labelName"
		colorField="colorString"
		[notes]="notes"
		type="column"
		[stack]="true"
		>
			<kendo-chart-series-item-tooltip>
				<ng-template let-category="category" let-value="value" let-series="series">
					<div class="toolTip">
						<div>{{ series.name }}</div>
						<div>
						{{ value }}
						</div>
					</div>
				</ng-template>
			</kendo-chart-series-item-tooltip>
		</kendo-chart-series-item>
	</kendo-chart-series>
</kendo-chart>
`;
	public StackedBarChartTs: any = `
public labelOptions: CategoryAxisLabels = {
	margin: {
		top: 0
	}
};

public notes: SeriesNotes = {
	label: {
		position: 'inside',
		font: '12px Arial',
		visible: false
	},
	line: {
		width: 0
	},
	icon: {
		visible: false
	},
	position: 'top'
};
`;
}
