// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const dataTableStylesDefaultTheme = [
	{
		name: 'Header background',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Header text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Header Border',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Grid row background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Grid row background (alternate)',
		property: 'background-color',
		value: 'fafafa',
		isColorTile: true,
		
	},
	{
		name: 'Grid Row Border',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'E9EBEC',
		isColorTile: true,
	},
];

export const dataTableStylesDarkTheme = [
	{
		name: 'Header background',
		property: 'background-color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: 'Header text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Header Border',
		property: 'border-color',
		value: '6b6b6b',
		isColorTile: true,
	},
	{
		name: 'Grid row background',
		property: 'background-color',
		value: '3b3b3b',
		isColorTile: true,
	},
	{
		name: 'Grid row background (alternate)',
		property: 'background-color',
		value: '242424',
		isColorTile: true,
	},
	{
		name: 'Grid Row Border',
		property: 'border-color',
		value: '6b6b6b',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
{
		name: 'Background: hover',
		property: 'background-color',
		value: '595959',
		isColorTile: true,
	},
];

export const dataTableStylesTypography = [
	{
		name: 'Column header text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: 'Grid cell text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const dataTableStylesStructure = [
	{
		name: 'First Header Row Cell',
		property: 'border-left-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},

	{
		name: 'Inner Header Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},

	{
		name: 'Last Header Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	
	{
		name: 'First Data Row Cell',
		property: 'border-left-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: 'Inner Data Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: 'last Data Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},

	{
		name: 'Grid cell',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Pagination Container',
		property: 'border-bottom-left-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-bottom-right-radius',
		value: '6px',
		isColorTile: false,
	},
];
