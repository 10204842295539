// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: lineChartStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const lineChartStylesDefaultTheme = [
	{
		name: 'Line, dot border',
		property: 'stroke',
		value: 'f44336',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '673ab7',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '2196f3',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'ffc107',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '4caf50',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'c7eaf9',
		isColorTile: true,
	},
	{
		name: 'Dot: hover',
		property: 'stroke',
		value: 'f44336',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '673ab7',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '2196f3',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'ffc107',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '4caf50',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'c7eaf9',
		isColorTile: true,
	},
	{
		name: 'Axis lines',
		property: 'stroke',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Axis label text',
		property: 'fill',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Grid lines',
		property: 'stroke',
		value: 'd1d1d6',
		isColorTile: true,
	},
];

export const lineChartStylesDefaultThemeSeverity = [
	{
		name: 'Line color - Critical',
		property: 'background-color',
		value: '8c2000',
		isColorTile: true,
	},
	{
		name: 'Line color - High',
		property: 'background-color',
		value: 'f58509',
		isColorTile: true,
	},
	{
		name: 'Line color - Moderate',
		property: 'background-color',
		value: 'fac966',
		isColorTile: true,
	},
	{
		name: 'Line color - Low',
		property: 'background-color',
		value: '13bcce',
		isColorTile: true,
	},
];

export const lineChartStylesDefaultThemeStatus = [
	{
		name: 'Line color - success',
		property: 'stroke',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Line color - warning',
		property: 'stroke',
		value: 'FFE900',
		isColorTile: true,
	},
	{
		name: 'Line color - error',
		property: 'stroke',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Line color - informational',
		property: 'stroke',
		value: '007D9A',
		isColorTile: true,
	},
];

export const lineChartStylesDarkTheme = [
	{
		name: 'Line, dot border',
		property: 'stroke',
		value: 'ff8178',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'be99ff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '69bcff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'ffce3b',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '4184a3',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '67f06d',
		isColorTile: true,
	},
	{
		name: 'Dot: hover',
		property: 'stroke',
		value: 'ff8178',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'be99ff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '69bcff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: 'ffce3b',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '4184a3',
		isColorTile: true,
	},
	{
		name: '',
		property: 'stroke',
		value: '67f06d',
		isColorTile: true,
	},
	{
		name: 'Axis lines',
		property: 'stroke',
		value: '595959',
		isColorTile: true,
	},
	{
		name: 'Axis label text',
		property: 'fill',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Grid lines',
		property: 'stroke',
		value: '595959',
		isColorTile: true,
	},
];

export const lineChartStylesDarkThemeSeverity = [
	{
		name: 'Line color - Critical',
		property: 'background-color',
		value: 'fe5666',
		isColorTile: true,
	},
	{
		name: 'Line color - High',
		property: 'background-color',
		value: 'ff9c68',
		isColorTile: true,
	},
	{
		name: 'Line color - Moderate',
		property: 'background-color',
		value: 'fcdf7d',
		isColorTile: true,
	},
	{
		name: 'Line color - Low',
		property: 'background-color',
		value: '71cfe6',
		isColorTile: true,
	},
];

export const lineChartStylesDarkThemeStatus = [
	{
		name: 'Line color - success',
		property: 'stroke',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Line color - warning',
		property: 'stroke',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Line color - error',
		property: 'stroke',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Line color - informational',
		property: 'stroke',
		value: '6699CC',
		isColorTile: true,
	},
];

export const lineChartStylesTypography = [
	{
		name: 'Axis label text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Chart legend text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];
export const lineChartStylesStructure = [
	{
		name: 'Container Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Line width',
		property: 'stroke-width',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Dot border',
		property: 'stroke-width',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Axis lines',
		property: 'stroke-width',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Grid lines',
		property: 'stroke-width',
		value: '1px',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const lineChartStylesDefaultThemeOld = [
	{
		name: 'Line color',
		property: 'background-color',
		value: '6699FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FC814C',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99F99F',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'CC99FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FFD35B',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FF551E',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99CCCC',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Grid lines',
		property: 'border-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
];

export const lineChartStylesDefaultThemeSeverityOld = [
	{
		name: 'Line color - critical',
		property: 'background-color',
		value: '783A3B',
		isColorTile: true,
	},
	{
		name: 'Line color - high',
		property: 'background-color',
		value: 'C67839',
		isColorTile: true,
	},
	{
		name: 'Line color - moderate',
		property: 'background-color',
		value: 'C6B539',
		isColorTile: true,
	},
	{
		name: 'Line color - low',
		property: 'background-color',
		value: '3992C6',
		isColorTile: true,
	},
];

export const lineChartStylesDefaultThemeStatusOld = [
	{
		name: 'Line color - success',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Line color - warning',
		property: 'background-color',
		value: 'FFE900',
		isColorTile: true,
	},
	{
		name: 'Line color - error',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Line color - informational',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const lineChartStylesDarkThemeOld = [
	{
		name: 'Line color (40% opacity)',
		property: 'background-color',
		value: '6699FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FC814C',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99F99F',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'CC99FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FFD35B',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FF551E',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99CCCC',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '1px border that matches the fill color (but solid)',
		isColorTile: false,
	},
	{
		name: 'Grid lines',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
];

export const lineChartStylesDarkThemeSeverityOld = [
	{
		name: 'Line Color - Critical',
		property: 'background-color',
		value: 'D6B9FF',
		isColorTile: true,
	},
	{
		name: 'Line Color - High',
		property: 'background-color',
		value: 'F39681',
		isColorTile: true,
	},
	{
		name: 'Line Color - Moderate',
		property: 'background-color',
		value: 'F3C781',
		isColorTile: true,
	},
	{
		name: 'Line Color - Low',
		property: 'background-color',
		value: '71CFE6',
		isColorTile: true,
	},
];

export const lineChartStylesDarkThemeStatusOld = [
	{
		name: 'Line Color - Success',
		property: 'background-color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Line Color - Warning',
		property: 'background-color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Line Color - Error',
		property: 'background-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Line Color - Informational',
		property: 'background-color',
		value: '6699CC',
		isColorTile: true,
	},
];

export const lineChartStylesStructureOld = [
	{
		name: 'Border',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
];