// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const menuStylesDefaultTheme = [
	{
		name: 'Background',
		property: 'color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Separator',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'E9EBEC',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const menuStylesDefaultThemeButtonText = [
	{
		name: 'Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Icon: hover / focus',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const menuStylesDarkTheme = [
	{
		name: 'Background',
		property: 'color',
		value: '121212',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '949494',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Separator',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '3b3b3b',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const menuStylesDarkThemeButtonText = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text: hover / focus',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Icon: hover / focus',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - transparent',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'background-color',
		value: '034969',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const menuStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const menuStylesStructure = [
	{
		name: 'Container',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '240px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '400px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Separator',
		property: 'height',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Menu Item',
		property: 'height',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Menu Item Icon',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];
