// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const datePickerStylesDefaultTheme = [
	{
		name: 'Background',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true
	},
	{
		name: 'Input Text',
		property: 'border-color',
		value: '15293A',
		isColorTile: true
	},
	{
		name: 'Month List Text',
		property: 'color',
		value: '15293A',
		isColorTile: true
	},
	{
		name: 'Month List Text:hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true
	},
	{
		name: 'Month List Background',
		property: 'background-color',
		value: 'e4e7ec',
		isColorTile: true
	},
	{
		name: 'Selected Month Background',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true
	},
	{
		name: 'Selected Day Background',
		property: 'background-color',
		value: '1c63a5',
		isColorTile: true
	},
	{
		name: 'Selected Day Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true
	},
	{
		name: '"Today" Day Number Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true
	},
	{
		name: 'Day Number Text',
		property: 'color',
		value: '081826',
		isColorTile: true
	},
	{
		name: 'Day Number Text:hover',
		property: 'color',
		value: '081826',
		isColorTile: true
	},
	{
		name: '',
		property: 'background-color',
		value: 'e4e7ec',
		isColorTile: true
	},
	{
		name: 'Day Name Text',
		property: 'color',
		value: '15293A',
		isColorTile: true
	},
	{
		name: 'Scrolling calendar month name',
		property: 'color',
		value: '15293A',
		isColorTile: true
	},
	{
		name: 'Label text',
		property: 'color',
		value: '081826',
		isColorTile: true
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: '15293A',
		isColorTile: true
	}
];

export const datePickerStylesDefaultPrimaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '1c63a5',
		isColorTile: true
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'deeeff',
		isColorTile: true
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'C14100',
		isColorTile: true
	}
];

export const datePickerStylesDefaultSecondaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '1c63a5',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '1c63a5',
		isColorTile: true
	},
];

export const datePickerStylesDarkTheme = [
	{
		name: 'Background',
		property: 'background-color',
		value: '121212',
		isColorTile: true
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '949494',
		isColorTile: true
	},
	{
		name: 'Input Text',
		property: 'border-color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Month List Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Month List Text:hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true
	},
	{
		name: 'Month List Background',
		property: 'background-color',
		value: '3b3b3b',
		isColorTile: true
	},
	{
		name: 'Selected Month Background',
		property: 'background-color',
		value: '121212',
		isColorTile: true
	},
	{
		name: 'Selected Day Background',
		property: 'background-color',
		value: '53b1fd',
		isColorTile: true
	},
	{
		name: 'Selected Day Text',
		property: 'color',
		value: '121212',
		isColorTile: true
	},
	{
		name: '"Today" Day Number Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true
	},
	{
		name: 'Day Number Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Day Number Text:hover',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: '',
		property: 'background-color',
		value: '3b3b3b',
		isColorTile: true
	},
	{
		name: 'Day Name Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Scrolling calendar month name',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Label text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	}
];

export const datePickerStylesDarkPrimaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '53b1fd',
		isColorTile: true
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true
	},
];

export const datePickerStylesDarkSecondaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '53b1fd',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '53b1fd',
		isColorTile: true
	},
];

export const datePickerStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false
	},
	{
		name: 'Text (Jan in month list)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false
	},
	{
		name: 'Text (scrolling calendar month name)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false
	}
];

export const datePickerStylesStructure = [
	{
		name: 'Container',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Selected Day',
		property: 'border-radius',
		value: '50%',
		isColorTile: false
	}
];
export const datePickerStylesTextInputStructure = [
	{
		name: 'Container',
		property: 'width',
		value: 'initial (approx. 210px)',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '30px',
		isColorTile: false
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false
	},
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
];

export const datePickerStylesButtonTextStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Container Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];
